import { useTranslation } from 'react-i18next';
import './infor.css';
function Infor() {
    const [t, i18n] = useTranslation("global");
    return (  
        <>
            <section id="madetour">
            <div>
                <h1 id="isTranslated" className="inforTitle fs-secondaryHeading clr-main ff-main-extraBold container">HÃY TỰ SÁNG TẠO CHO <br />
                    CHÍNH CHUYẾN ĐI CỦA BẠN</h1>
               <div className="formWrapper">
                    <form action="" className="flexColCenter">
                        <h2 id="isTranslated" className="ff-main-semibold clr-white">Gửi thông tin của bạn
                            cho chúng tôi</h2>
                        <div className="formFields flexColCenter">
                            <div className="fieldsWrapper flexColCenter">
                                <label id="isTranslated" className="clr-white ff-main-regular" htmlFor="fullname">Họ và tên</label>
                                <input type="text" id="fullname" />
                            </div>
                            <div className="fieldsWrapper flexColCenter">
                                <label id="isTranslated" className="clr-white ff-main-regular" htmlFor="phone">Số điện thoại</label>
                                <input type="number" id="phone" />
                            </div>
                            <div className="fieldsWrapper flexColCenter">
                                <label className="clr-white ff-main-regular" htmlFor="email">Email</label>
                                <input type="email" id="email" />
                            </div>
                            <div className="fieldsWrapper flexColCenter">
                                <label id="isTranslated" className="clr-white ff-main-regular" htmlFor="note">Ghi Chú</label>
                                <textarea name="" id="note" cols="30" rows="10" ></textarea>
                            </div>                    
                        </div>
                        <button id="isTranslated" type="submit" className="button btnHover clr-white ff-main-regular btnDialog" data-button="green">Gửi thông tin</button>
                    </form>


               </div>

            </div>

            </section>
            <dialog data-type="home">
                <div className="flexColCenter text-center card">
                    <i><img src="./images/details/icon-check.svg" alt="check" /></i>
                    <p className="fs-nav ff-main-regular"><span className="ff-main-semibold clr-green">Cảm ơn bạn đã quan tâm đến D-Tour! <br/></span>Chúng tôi sẽ liên hệ cho bạn sớm nhất có thể.</p>
                    <p className="fs-nav ff-main-regular">Có vấn đề gì thắc mắc quý khách vui lòng liên hệ để được giải đáp: 
                        <br/><span className="ff-main-semibold">Hotline:</span> +(84) 283 123 567 890
                        <br/><span className="ff-main-semibold">Email:</span> support@d-tour.vn</p>
                    <button className="button clr-white ff-main-regular btnHover" data-button="green" id="closeBtn">Đóng</button>
                </div>
            </dialog>
        </>     
     );
}

export default Infor;