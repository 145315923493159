import { Link, useParams } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import { useEffect, useState } from 'react';

import './outstandingdetail.css';
import { dataImg, data, dataSchedule } from '../../utils/data-outstading';
import { useRef } from 'react';
import {motion as m} from 'framer-motion';

function OutstandingDetail() {
    const [currentIndexImg, setCurrentIndexImg] = useState(0);
    const {id} = useParams();

    const imgObject = dataImg.filter(img => img.id === id)[0];    
    const {imgCarousel, imgAutoPlay, imgSchedule} = imgObject;  
    
    const item = data.filter( d => d.id === id)[0];
    const schedule = dataSchedule.filter(d => d.id === id);
    const {day1, day2} = schedule[0];
    const scheduleDay1SangItem2Content = useRef();
    const scheduleDay1TruaItem1Content = useRef();
    const scheduleDay2SangItem2Content = useRef();
    const scheduleDay2TruaItem1Content = useRef();
    const scheduleDay2TruaItem3Content = useRef();

    const handleClickBtn = (btn) => {
        const imgList = document.querySelectorAll('.img-item');
        let lengthOfListImg = imgList.length;
        let index = 0;
        switch (btn) {
            case "prev":
                if(currentIndexImg !== 0) {
                    index = currentIndexImg - 1;
                } else {
                    index = lengthOfListImg - 1;
                }
                document.querySelector('.img-item.active').classList.remove("active");
                imgList[index].classList.add("active");
                setCurrentIndexImg(index);
                break;
            case "next":       
                if(currentIndexImg < lengthOfListImg - 1) {
                    index = currentIndexImg + 1;
                } else {
                    index = 0;
                }
                document.querySelector('.img-item.active').classList.remove("active");
                imgList[index].classList.add("active");
                setCurrentIndexImg(index);
                break;
        
            default:
                throw new Error("invalid type!");
        }
    }

    useEffect(() => {
        const detailsImages = document.querySelectorAll('.detailsRightItem');
        const autoSlider = setInterval(() => {
            const itemActive = document.querySelector('.detailsRightItem.active');
            switch (itemActive) {
                case detailsImages[0]:
                    itemActive.classList.remove('active');
                    detailsImages[1].classList.add('active');
                    break;
                case detailsImages[1]:
                    itemActive.classList.remove('active');
                    detailsImages[2].classList.add('active');
                    break;
                case detailsImages[2]:
                    itemActive.classList.remove('active');
                    detailsImages[0].classList.add('active');
                    break;  
                default: 
                    throw new Error("invalid type!");              
            }
        }, 2000)
        return () => clearInterval(autoSlider);

    },[]);
    
    useEffect(() => {
        scheduleDay1SangItem2Content.current.innerHTML = day1.sang.item2.content;
        scheduleDay1TruaItem1Content.current.innerHTML = day1.trua.item1.content;
        scheduleDay2SangItem2Content.current.innerHTML = day2.sang.item2.content;
        scheduleDay2TruaItem1Content.current.innerHTML = day2.trua.item1.content;
        scheduleDay2TruaItem3Content.current.innerHTML = day2.trua.item3.content;
        
    },[]);

    return ( 
        <>
            <Header navigate="/" />

            <m.main
              initial={{opacity: 0}}
              animate={{opacity: 1}}                
              exit={{opacity: 0}}
              transition={{duration: 0.5, ease: "easeInOut"}}
            >
                {/* <!--carousel--> */}
                <section>
                    <div className="carousel">
                            <button className="back button">
                                <Link to="/">
                                    <img className="pointer-none" src="../../../images/icons/icon-back.svg" alt="back icon" />
                                </Link>
                            </button>
                            <button onClick={() => handleClickBtn("prev")}  className="carouselBtn" id="prev">
                                <img src="../../../images/icons/icon-arrowLeft.svg" alt="arrow left" />
                            </button>           
                            <button onClick={() => handleClickBtn("next")}  className="carouselBtn" id="next">
                                <img src="../../../images/icons/icon-arrowRight.svg" alt="arrow right" />
                            </button>  
                            <div className="img-wrapper">
                                <img className="img-item active" src={imgCarousel.img1} alt="carousel" />             /     
                                <img className="img-item" src={imgCarousel.img2} alt="carousel" />       
                                <img className="img-item" src={imgCarousel.img3} alt="carousel" />       
                            </div>         
                        </div>
                </section>
                {/* <!--information--> */}
                <section>
                    <div className="container infor">
                        <h1 id='isTranslated'  className="text-center clr-main ff-main-semibold fs-secondaryHeading">{item.cta} {item.title}</h1>
                        <p id='isTranslated' className="text-center fs-nav">
                            {item.description}
                        </p>
                        <div className="inforButtonGroup flexCenter">
                            <button id='isTranslated' className=" clr-white ff-main-regular">Tải về chi tiết</button>
                            <button id='isTranslated' className="clr-main ff-main-regular cursor-pointer btnDialog">Nhận tư vấn</button>
                        </div>
                    </div>
                </section>
                <dialog  >         
                    <form className="flexColCenter dialogForm" action="">
                        <h2  className="ff-main-semibold clr-white">Gửi thông tin của bạn
                            cho chúng tôi</h2>
                        <div className=" flexColCenter">
                            <div className=" flexColCenter">
                                <label  className=" ff-main-regular" htmlFor="fullname">Họ và tên</label>
                                <input type="text" id="fullname" />
                            </div>
                            <div className=" flexColCenter">
                                <label  className=" ff-main-regular" htmlFor="phone">Số điện thoại</label>
                                <input type="number" id="phone" />
                            </div>
                            <div className=" flexColCenter">
                                <label className=" ff-main-regular" htmlFor="email">Email</label>
                                <input type="email" id="email" />
                            </div>
                            <div className=" flexColCenter">
                                <label  className="ff-main-regular" htmlFor="note">Ghi Chú</label>
                                <textarea name="" id="note" cols="30" rows="10" ></textarea>
                            </div>                    
                        </div>
                        <button  type="submit" className="button btnHover clr-white cursor-pointer  ff-main-regular" data-button="green">Gửi thông tin</button>
                    </form> 
                </dialog>   
                {/* <!--tour details--> */}
                <section>
                    <div className="container">
                        <div className="columns-auto">
                            <div className="detailsLeft">
                                <div className="detailsLeftTop">
                                    <div className="row flexCenter">
                                        <div className="detailsLeftTopItem">
                                            <i><img src="../../../images/icons/icon-money.svg" alt="money"/></i>
                                            <p  className="fs-secondaryHeading clr-main ff-main-semibold">Price</p>
                                            <span className="ff-main-light">15.000.000</span>
                                        </div>
                                        <div className="detailsLeftTopItem">
                                            <i><img src="../../../images/icons/icon-calendar.svg" alt="calendar"/></i>
                                            <p  className="fs-secondaryHeading clr-main ff-main-semibold">When</p>
                                            <span className="ff-main-light">12/03/2024</span>
                                        </div>
                                    </div>
                                    <div className="row flexCenter" data-gap="sm">
                                        <div className="detailsLeftTopItem">
                                            <i><img src="../../../images/icons/icon-clock.svg" alt="duration"/></i>
                                            <p  className="fs-secondaryHeading clr-main ff-main-semibold">Duration</p>
                                            <span className="ff-main-light">{item.duration}</span>
                                        </div>
                                        <div className="detailsLeftTopItem">
                                            <i><img src="../../../images/icons/icon-location.svg" alt="duration"/></i>
                                            <p  className="fs-secondaryHeading clr-main ff-main-semibold">Where</p>
                                            <span className="ff-main-light">{item.where}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="detailsLeftBottom">
                                    <div className="flexCenter">
                                        <i><img src="../../../images/icons/icon-duration.png" alt="location"/></i>
                                        <h1 className="fs-secondaryHeading clr-highlight ff-main-semibold">Duration</h1>
                                    </div>
                                    <p id='isTranslated' className="ff-main-light">Được hổ trợ visa nhanh chóng. Tặng miễn phí bao da hộ chiếu,...</p>
                                </div>
                            </div>

                            <div className="detailsRight">
                                <img className="detailsRightItem active" src={imgAutoPlay.img1} alt="duration"/>
                                <img className="detailsRightItem" src={imgAutoPlay.img2} alt="duration"/>
                                <img className="detailsRightItem" src={imgAutoPlay.img3} alt="duration"/>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--schedule--> */}
                <section>
                    <div className="container schedule">
                        <h1  className="ff-main-extraBold clr-main fs-secondaryHeading">SCHEDULE</h1>
                        <div className="columns-auto scheduleMain">
                            <div className="scheduleLeft">
                                <img src={imgSchedule.img1} alt="schedule" />
                                <img src={imgSchedule.img3} alt="schedule" />
                                <img src={imgSchedule.img2} alt="schedule" />
                            </div>

                            <div className="scheduleRight flexColCenter">                                
                                <div className="scheduleRightTop">
                                    <p id='isTranslated' className="clr-text ff-main-bold fs-secondaryHeading">Ngày 1:</p>
                                    <p id='isTranslated' className="clr-main ff-main-bold fs-secondaryHeading">{day1.title}</p>
                                </div>

                                <div className="scheduleRightBottom">
                                    <div className="scheduleRightBottomItem">
                                        <p id='isTranslated' className="ff-main-bold clr-text">Sáng</p>
                                        <div className="columns-auto">
                                            <div className="scheduleRightBottomItemLine"><span></span></div>
                                            <div className="scheduleRightBottomItemContent flexColCenter clr-black">
                                                <div className="flexCenter">
                                                    <p id='isTranslated' className="clr-highlight ff-main-semibold">{day1.sang.item1.time}</p>
                                                    <p id='isTranslated' className="ff-main-regular fs-nav">{day1.sang.item1.content}</p>
                                                </div>
                                                <div className="flexCenter">
                                                    <p id='isTranslated' className="clr-highlight ff-main-semibold">{day1.sang.item2.time}</p>
                                                    <p id='isTranslated' ref={scheduleDay1SangItem2Content} className="ff-main-regular fs-nav">                                                   
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="scheduleRightBottomItem">
                                        <p  className="ff-main-bold clr-text">Trưa</p>
                                        <div className="columns-auto">
                                            <div className="scheduleRightBottomItemLine" data-type="oneRow"><span></span></div>
                                            <div className="scheduleRightBottomItemContent flexCenter clr-black" data-type="oneRow">
                                                <p  className="clr-highlight ff-main-semibold">{day1.trua.item1.time}</p>
                                                <div>
                                                    <p ref={scheduleDay1TruaItem1Content} id='isTranslated' class="fs-nav ff-main-regular clr-black"></p>
                                                </div>
                                            </div>       
                                        </div>
                                    </div>
                                    <div className="scheduleRightBottomItem">
                                        <p  className="ff-main-bold clr-text">Tối</p>
                                        <div className="columns-auto">
                                            <div className="scheduleRightBottomItemLine"><span></span></div>
                                            <div className="scheduleRightBottomItemContent flexColCenter clr-black">
                                                <div className="flexCenter">
                                                    <p id='isTranslated' className="clr-highlight ff-main-semibold">{day1.toi.item1.time}</p>
                                                    <p id='isTranslated' className="ff-main-regular fs-nav">{day1.toi.item1.content}</p>
                                                </div>
                                                <div className="flexCenter">
                                                    <p id='isTranslated' className="clr-highlight ff-main-semibold">{day1.toi.item2.time}</p>
                                                    <p id='isTranslated' className="ff-main-regular fs-nav">{day1.toi.item2.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns-auto scheduleMain" data-type="dayTwo">

                            <div className="scheduleRight flexColCenter">
                                <div className="scheduleRightTop">
                                    <p id='isTranslated' className="clr-text ff-main-bold fs-secondaryHeading">Ngày 2:</p>
                                    <p id='isTranslated' className="clr-main ff-main-bold fs-secondaryHeading">{day2.title}</p>
                                </div>

                                <div className="scheduleRightBottom">
                                    <div className="scheduleRightBottomItem">
                                        <p id='isTranslated' className="ff-main-bold clr-text">Sáng</p>
                                        <div className="columns-auto">
                                            <div className="scheduleRightBottomItemLine"><span></span></div>
                                            <div className="scheduleRightBottomItemContent flexColCenter clr-black">
                                                <div className="flexCenter">
                                                    <p  className="clr-highlight ff-main-semibold">{day2.sang.item1.time}</p>
                                                    <p id='isTranslated' className="ff-main-regular fs-nav">{day2.sang.item1.content}</p>
                                                </div>
                                                <div className="flexCenter">
                                                    <p  className="clr-highlight ff-main-semibold">{day2.sang.item2.time}</p>
                                                    <p ref={scheduleDay2SangItem2Content} id='isTranslated' className="ff-main-regular fs-nav">
                                                        
                                                       
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="scheduleRightBottomItem">
                                        <p id='isTranslated' className="ff-main-bold clr-text">Trưa</p>
                                        <div className="columns-auto">
                                            <div className="scheduleRightBottomItemLine">
                                                <span></span>
                                                <span className="dotCustom"></span>
                                            </div>
                                            <div className="scheduleRightBottomItemContent flexColCenter clr-black" data-type="dayTwo">
                                                <div className="flexCenter">
                                                    <p  className="clr-highlight ff-main-semibold">{day2.trua.item1.time}</p>                                                                  
                                                    <p ref={scheduleDay2TruaItem1Content} id='isTranslated' className="fs-nav ff-main-regular clr-black"></p> 
                                                </div>
                                                <div className="flexCenter">
                                                    <p  className="clr-highlight ff-main-semibold">{day2.trua.item2.time}</p>                                                                  
                                                    <p id='isTranslated' className="fs-nav ff-main-regular clr-black customTranslate">{day2.trua.item2.content}</p> 
                                                </div>
                                                <div className="flexCenter ">
                                                    <p  className="clr-highlight ff-main-semibold">{day2.trua.item3.time}</p>                                                                  
                                                    <p ref={scheduleDay2TruaItem3Content} id='isTranslated' className="fs-nav ff-main-regular clr-black"></p> 
                                                </div>
                                                <div className="flexCenter ">
                                                    <p  className="clr-highlight ff-main-semibold">{day2.trua.item4.time}</p>                                                                  
                                                    <p id='isTranslated' className="fs-nav ff-main-regular clr-black">{day2.trua.item4.content}</p>
                                                </div>
                                                                
                                            </div>       
                                        </div>
                                    </div>
                                    <div className="scheduleRightBottomItem">
                                        <p id='isTranslated' className="ff-main-bold clr-text">Tối</p>
                                        <div className="columns-auto">
                                            <div className="scheduleRightBottomItemLine"><span className="dotCustom" data-dot="low"></span></div>
                                            <div className="scheduleRightBottomItemContent flexColCenter clr-black">
                                                <div className="flexCenter">
                                                    <p  className="clr-highlight ff-main-semibold">{day2.toi.item1.time}</p>
                                                    <p id='isTranslated' className="ff-main-regular fs-nav">{day2.toi.item1.content}</p>
                                                </div>
                                                <div className="flexCenter">
                                                    <p  className="clr-highlight ff-main-semibold">{day2.toi.item2.time}</p>
                                                <p id='isTranslated' className="ff-main-regular fs-nav">{day2.toi.item2.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="scheduleLeft">
                                <img src={imgSchedule.img1} alt="schedule" />
                                <img src={imgSchedule.img3} alt="schedule" />
                                <img src={imgSchedule.img2} alt="schedule" />
                            </div>                 
                        </div>
                    </div>
                </section>
            </m.main>

            <Footer />
        </>
     );
}

export default OutstandingDetail;