import { Link } from "react-router-dom";

function OurCustomer() {
    return ( 
        <div class="cardItem justify-content-end" data-card="introduce">
            <Link to="/ourcustomer"><p class="pointer-none clr-main ff-main-bold fs-lg-100">Our Customer</p></Link>
        </div>
     );
}

export default OurCustomer;