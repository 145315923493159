export const data = [   
    {
        image: './images/packagetour/package_indonesia.png',
        title: 'Indonesia',
    },
    {
        image: './images/packagetour/package_japan_first.png',
        title: 'Japan',
    },
    {
        image: './images/packagetour/package_thailand.png',
        title: 'Thailand',
    },
    {
        image: './images/packagetour/package_korea.png',
        title: 'Korea',
    },
    {
        image: './images/packagetour/package_seoul.png',
        title: 'Seoul',
    },
    {
        image: './images/packagetour/package_bali.png',
        title: 'Bali',
    },
    {
        image: './images/packagetour/package_phuket.png',
        title: 'Phuket',
    },
    {
        image: './images/packagetour/package_japan_second.png',
        title: 'Japan',
    },
]