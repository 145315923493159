import './packagetour.css';
import {data} from '../../utils/data-packagetour';
import CardPackage from '../../components/CardPackage';
import { useState } from 'react';

function PackageTour() {
    const itemRef = useState(null);
    const [isMousedown, setIsMousedown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [valueInputRange, setValueInputRange] = useState(20);


    const handleMouseDown = (e) => {
        setIsMousedown(true);
        setStartX(e.pageX - itemRef.current.offsetLeft);
        setScrollLeft(itemRef.current.scrollLeft);
    }
    const handleMouseUp = () => {
        setIsMousedown(false);
    }
    const handleMouseLeave = () => {
        setIsMousedown(false)
    }
    const handleMouseMove = (e) => {
        if(!isMousedown) return;
        e.preventDefault();
        let x = e.pageX - itemRef.current.offsetLeft;
        let walk = (x-startX)*1.3;
        itemRef.current.scrollLeft = scrollLeft - walk;
    }
    const handleInput = (e) => {
        setValueInputRange(e.target.value);
    }
    return (     
    <section class="packageWrapper" id="packagetour">
        <div>
            <div class="packageMain">
                <h1 class="primaryHeading container fs-secondaryHeading clr-main ff-main-extraBold">GENERAL PACKAGE TOUR</h1>      
                <div class="filter flexCenter container">
                    <ul class="flexCenter" role="list">
                        <li>
                            <label for="date" class="clr-main ff-main-semibold">Date</label>
                            <select name="Date" id="date" class="ff-main-extralight clr-text">
                                <option value="">Select Date</option>
                            </select>
                        </li>                     
                        <li>
                            <label for="season" class="clr-main ff-main-semibold">Season</label>
                            <select name="Date" id="season" class="ff-main-extralight clr-text">
                                <option value="">Select Season</option>
                            </select>
                        </li>                     
                        <li>
                            <label for="date" class="clr-main ff-main-semibold">Country</label>
                            <select name="Date" id="date" class="ff-main-extralight clr-text">
                                <option value="">Select Country</option>
                            </select>
                        </li>                     
                        <li class="duration flexCenter">
                            <label for="duration" class="clr-main ff-main-semibold">Duration</label>
                            <div class="inputRangeWrapper flexColCenter">
                                <input type="range" min="2" max="20" value={valueInputRange}
                                onInput={handleInput}
                                />
                                <p class="text-center ff-main-light">1 Days - <span class="valueInputRange">{valueInputRange}</span> Days</p>
                            </div>
                        </li>
                    </ul>       
                    
                    <button id="isTranslated" class="button btnHover ff-main-regular clr-white" data-button="green">Tìm kiếm</button>
                </div>

                <div class="cardList flexCenter" data-card="package tour" ref={itemRef}
                   onMouseDown={handleMouseDown}
                   onMouseUp={handleMouseUp}
                   onMouseLeave={handleMouseLeave}
                   onMouseMove={handleMouseMove}
                >
                    {
                        data.map((item) => (
                            <CardPackage item={item} />
                        ))
                    }        
                </div>
            </div>
        </div>
    </section>
 );
}

export default PackageTour;