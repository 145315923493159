import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Infor from "./infor/Infor";
import Introduction from "./introduction/Introduction";
import OutStanding from "./outstanding/OutStanding";
import PackageTour from "./packagetour/PackageTour";
import {motion as m} from "framer-motion";

function Home() {
    return ( 
        <>
        <Header />
        <m.main
        initial={{opacity: 0}}
        animate={{opacity: 1}}                
        exit={{opacity: 0}}
        transition={{duration: 0.5, ease: "easeInOut"}}
        >
            <OutStanding />
            <PackageTour />
            <Infor />
            <Introduction />
        </m.main>
        <Footer />
        </>
     );
}

export default Home;