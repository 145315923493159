import { Link } from "react-router-dom";

function CardPackage({item}) {
    let link = `/packagetourdetail/123`;
    return ( 
        <div class="cardItem" data-card="package tour">
            <img src={item.image} alt="indonesia" />
            <Link to={link}><p class="text-uppercase pointer-none clr-white ff-main-semibold">{item.title}</p></Link>
        </div>         
     );
}

export default CardPackage;