import './outstanding.css';
import {data} from '../../utils/data-outstading';
import CardOutstanding from '../../components/CardOutstanding';
import { useState } from 'react';



function OutStanding() {

    const itemRef = useState(null);
    const [isMousedown, setIsMousedown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsMousedown(true);
        setStartX(e.pageX - itemRef.current.offsetLeft);
        setScrollLeft(itemRef.current.scrollLeft);
    }
    const handleMouseUp = () => {
        setIsMousedown(false);
    }
    const handleMouseLeave = () => {
        setIsMousedown(false)
    }
    const handleMouseMove = (e) => {
        if(!isMousedown) return;
        e.preventDefault();
        let x = e.pageX - itemRef.current.offsetLeft;
        let walk = (x-startX)*1.3;
        itemRef.current.scrollLeft = scrollLeft - walk;
    }
    return (      
        <section className="outstandingWrapper" id="outstanding">      
               <div className="outStandingMain">
                <h1 className="ff-main-bold fs-primaryHeading clr-main container">OUTSTANDING TOUR</h1>
                <div className="flexCenter cardList" data-card="outstanding" ref={itemRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                >       
                    {
                        data.map((item) =>(
                            <CardOutstanding item={item} key={item.id} />
                        )
                        )
                    }
                </div>
               </div>   
        </section>
     );
}

export default OutStanding;