export const data = [
    {
        id: 'osd01',
        image: './images/outstanding/outstanding_korea.png',
        cta: 'trải nghiệm',
        title: 'DU LỊCH MÙA ABC TẠI HÀN QUỐC',
        price: '15.000.000',
        date: '12/03/2024',    
        description: "Khám phá vẻ đẹp___________ tại Hàn Quốc trong chuyến du lịch mùa ABC. Đắm chìm trong khung cảnh _____________, thưởng thức ẩm thực đặc trưng và trải nghiệm văn hóa độc đáo của xứ sở Kim chi. Chuyến đi sẽ mang lại những kỷ niệm đáng nhớ và trải nghiệm không thể quên.",
        duration: "2 Days",
        where: "Seoul - Nami Island",
    },
    {
        id: 'osd02',
        image: './images/outstanding/outstanding_dailoan.png',
        cta: 'khám phá',
        title: 'DU LỊCH MÙA LÁ ĐỎ TẠI ĐÀI LOAN',
        price: '15.000.000',
        date: '12/03/2024',
    },
    {
        id: 'osd03',
        image: './images/outstanding/outstanding_indonesia.png',
        cta: 'khám phá',
        title: 'VIÊN NGỌC CỦA XỨ VẠN ĐẢO INDONESIA',
        price: '15.000.000',
        date: '12/03/2024',
    },
    {
        id: 'osd04',
        image: './images/outstanding/outstanding_campuchia.png',
        cta: 'trải nghiệm',
        title: 'HẤP DẪN CỦA XỨ SỞ CHÙA VÀNG',
        price: '15.000.000',
        date: '12/03/2024',
    },
]

export const dataImg = [
    {
        id: 'osd01',
        imgCarousel:{
            img1: "../../../images/outstanding/carousel_korea_1.png",
            img2: "../../../images/outstanding/carousel_korea_2.png",
            img3: "../../../images/outstanding/carousel_korea_3.png"
        },
        imgAutoPlay: {
            img1: "../../../images/outstanding/duration_1.png",
            img2: "../../../images/outstanding/duration_2.png",
            img3: "../../../images/outstanding/duration_3.png",            
        },
        imgSchedule: {
            img1: "../../../images/outstanding/schedule_1.png",
            img2: "../../../images/outstanding/schedule_2.png",
            img3: "../../../images/outstanding/schedule_3.png",
        }
    }
]

export const dataSchedule = [
    {
        id: 'osd01',
        day1: {
            title: "CUNG ĐIỆN CÁNH PHÚC - BLUE HOUSE",
            sang: {
                item1: {
                    time: "07:00",
                    content: "Ăn sáng trên máy bay"
                },
                item2: {
                    time: "09:20",
                    content: `Đoàn đến sân bay Incheon, làm thủ tục nhập cảnh. Tiếp tục hành trình về thủ đô Seoul tham quan:                
                    <span class="ff-main-semibold">Cảnh Phúc Cung, Phủ Tổng Thống Hàn Quốc (còn gọi là Nhà Xanh)</span>
                    `,
                }
            },
            trua: {
                item1: {
                    time: "12:00",
                    content: `Đoàn ăn trưa tại nhà hàng.
                    Tiếp tục tham quan: <span class="ff-main-semibold">Làng cổ Bukchon Hanok, Tháp Nam San – N Seoul</span>`,                 
                },               
            },
            toi: {
                item1: {
                    time: "17:30",
                    content: "Đoàn dùng cơm tối.",                    
                },
                item2: {
                    time: "18:30",
                    content: "Check in hotel",                    
                }
            }
        },
        day2: {
            title: "SEOUL - ĐẢO NAMI - TRƯỢT TUYẾT",
            sang: {
                item1: {
                    time: "07:00",
                    content: "Ăn sáng tại khách sạn"
                },
                item2: {
                    time: "08:00",
                    content: `Tham quan  <span class="ff-main-semibold">Trung tâm nhân sâm chính phủ</span>`,             
                }
            },
            trua: {
                item1: {
                    time: "11:00",
                    content: `Khởi hành đến <span class="ff-main-semibold">Đảo Nami</span>`,            
                },               
                item2: {
                    time: "13:00",
                    content: "Đoàn ăn trưa tại nhà hàng địa phương",     
                },               
                item3: {
                    time: "14:30",
                    content: `Khởi hành đến <span class="ff-main-semibold">Khu trượt tuyết Elysian Gangchon:</span>
                    hệ thống khu giải trí nằm trong khung cảnh hoành tráng đầy tuyết trắng của vùng núi tỉnh Gangwondo`,                    
                },               
                item4: {
                    time: "16:30",
                    content: "Di chuyển về Seoul",                    
                },               
            },
            toi: {
                item1: {
                    time: "19:00",
                    content: "Đoàn ăn tối tại nhà hàng địa phương, sau đó về khách sạn và tự do nghỉ ngơi.",                    
                },
                item2: {
                    time: "21:00",
                    content: "Giao lưu cùng D-tour (2 chai Soju – mỗi người)",                    
                }
            }
        },
    },
]