import { Link } from 'react-router-dom';
import './header.css';
import { useEffect, useState } from 'react';

function Header({navigate}) {    
      
    const [paragraph, setParagraph] = useState([]);
    let vn = "vi-VN";
    let en = "en-GB";  
    const handleClick = (el) => {
        el.parentElement.classList.toggle('toggleHeight');
    }

    const handleTranslate = (el) => {
        const parent = el.parentElement;          
        if(el.innerText.toLowerCase() == 'en'){
            parent.firstChild.innerText = "EN";
            parent.lastChild.innerText = "VI";     
            paragraph.forEach((p) => {
                translate(p, p.innerText,vn, en );
            })      
        } else
        if(el.innerText.toLowerCase() == 'vi'){       
            parent.firstChild.innerText = "VI";            
            parent.lastChild.innerText = "EN";
            window.location.reload();    
        }
        parent.classList.toggle('toggleHeight');
    }

    useEffect(() => {
        let paragraph = document.querySelectorAll('#isTranslated');
        setParagraph(paragraph);
    }, [])
    function translate(textElement, textValue, from, to){ 
        let apiUrl = `https://api.mymemory.translated.net/get?q=${textValue}&langpair=${from}|${to}`;
        fetch(apiUrl)
          .then((res) => res.json())
          .then((data) => {       
            textElement.innerText = data.responseData.translatedText;
            if(Array.isArray(data.matches)) {
                data.matches.forEach((data) => {
                  if (data.id === 0) {
                    textElement.innerText = data.translation;
                  }
                });             
            } else {
                textElement.innerText = "The text can not be translated";
            }
          });  
        }
    return ( 
        navigate !== undefined ? 
        (
            <header>
                <div className="container">
                    <div className="headerMain flexCenter">
                        <div className="headerLogoWrapper">
                            <Link to="/" className="logo flexCenter">
                                <img className="pointer-none" src="../../../images/Logo-01.svg" alt="logo" />
                                <p className="pointer-none  ff-logo fs-logo clr-white">New you - New journey</p>
                            </Link>
                        </div>
            
                        <div className="headerNavWrapper flexCenter">
                            <nav>
                                <ul role="list" className="flexCenter">
                                    <li className="text-center"><Link to={navigate} data-type="not-transition" href="#outstanding" className="clr-white fs-nav ff-main-bold">OUTSTANDING TOUR</Link></li>
                                    <li className="text-center"><Link to={navigate} data-type="not-transition" href="#packagetour" className="clr-white fs-nav ff-main-bold">PACKAGE TOUR</Link></li>
                                    <li className="text-center"><Link to={navigate} data-type="not-transition" href="#madetour" className="clr-white fs-nav ff-main-bold">MICE/Tailor-made tour</Link></li>
                                    <li className="text-center"><Link to={navigate} data-type="not-transition" href="#details" className="clr-white fs-nav ff-main-bold">Our <br /> details</Link></li>
                                </ul>
                            </nav>
            
                        </div>
                        <div className="buttonWrapper">
                            <div className="buttonGroup flexColCenter">
                                <button onClick={(e) => handleClick(e.target)}  id="vn" className="button clr-main" data-button="translate">VI</button>
                                <button onClick={(e) => handleTranslate(e.target)}  id="en" className="button clr-main active" data-button="translate">EN</button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        ):
        (
            <header>
            <div className="container">
                <div className="headerMain flexCenter">
                    <div className="headerLogoWrapper">
                        <a href="/" className="logo flexCenter">
                            <img className="pointer-none" src="./images/Logo-01.svg" alt="logo" />
                            <p className="pointer-none  ff-logo fs-logo clr-white">New you - New journey</p>
                        </a>
                    </div>
        
                    <div className="headerNavWrapper flexCenter">
                        <nav>
                            <ul role="list" className="flexCenter">
                                <li className="text-center"><a data-type="not-transition" href="#outstanding" className="clr-white fs-nav ff-main-bold">OUTSTANDING TOUR</a></li>
                                <li className="text-center"><a data-type="not-transition" href="#packagetour" className="clr-white fs-nav ff-main-bold">PACKAGE TOUR</a></li>
                                <li className="text-center"><a data-type="not-transition" href="#madetour" className="clr-white fs-nav ff-main-bold">MICE/Tailor-made tour</a></li>
                                <li className="text-center"><a data-type="not-transition" href="#details" className="clr-white fs-nav ff-main-bold">Our <br /> details</a></li>
                            </ul>
                        </nav>
        
                    </div>
                    <div className="buttonWrapper">
                        <div className="buttonGroup flexColCenter">
                            <button onClick={(e) => handleClick(e.target)}  id="vn" className="button clr-main" data-button="translate">VI</button>
                            <button onClick={(e) => handleTranslate(e.target)}  id="en" className="button clr-main active" data-button="translate">EN</button>
                        </div>
                    </div>
                </div>
            </div>
            </header>
        )
     );
}

export default Header;