import { useTranslation } from 'react-i18next';
import './footer.css';
import { Link } from 'react-router-dom';
function Footer() {
    const [t, i18n] = useTranslation("global");
    return ( 
        <footer className="footerWrapper">
            <div className="container">
                <div className="footerMain flexCenter">
                    <div className="footerLeft flexCenter">
                        <Link to="/"><img className="f_logo" src="../../../images/Logo-01.svg" alt="logo" /></Link>
                        <div className="footerLeftContent flexColCenter">
                            <h3 id="isTranslated" className="ff-main-extraBold">{t("footer.companyName")}</h3>
                            <p className="ff-main-medium clr-box">Office: 2th floor, 116D Hoang Dieu, Ward 13, District 4, HCMC Ho Chi Minh</p>
                        </div>
                    </div>
                    <div className="footerRight flexColCenter">
                        <p className="ff-main-extraBold">Denny Nguyen (Mr.) <br /> <span className="ff-main-medium clr-box">Director</span></p>
                        <ul className="flexColCenter" role="list">
                            <li>
                                <p className="ff-main-medium clr-box">+84 978 272 977</p>
                            </li>
                            <li>
                                <p className="ff-main-medium clr-box">denny.nguyen@d-tour.vn</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
     );
}

export default Footer;