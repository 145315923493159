import { Link } from "react-router-dom";

function AboutUs() {
    return ( 
        <div class="cardItem" data-card="introduce">
            <Link to="/aboutus"><p class="pointer-none clr-main ff-main-bold fs-lg-100">About us</p></Link>
        </div>
     );
}

export default AboutUs;