import { Link } from "react-router-dom";

function CardOutstanding({item}) {  
    let link = `/outstanding/${item.id}`;
    return ( 
        <div className="flexColCenter cardItem" data-card="outstanding">
            <img src={item.image} alt="korea" />
            <div className="cardItemTopContent">
                <span id="isTranslated" className="clr-white ff-main-medium">{item.cta}</span>
                <p  id="isTranslated" className="secondaryHeading clr-white fs-secondaryHeading ff-main-extraBold">{item.title}</p>
            </div>
            <div className="cardItemBottomContent flexCenter">
                <div>
                    <p  id="isTranslated" className="secondaryHeading clr-white ff-main-extraBold fs-secondaryHeading">{item.price} VND</p>
                    <span className="clr-white ff-main-medium">Date: {item.date}</span>
                </div>
                <button  id="isTranslated" className="button clr-main ff-main-regular">
                    <Link className="clr-main viewMoreBtn" to={link}>Xem thêm</Link>
                </button>
            </div>
        </div>
     );
}

export default CardOutstanding;