import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import './introducedetail.css';
import {motion as m} from "framer-motion";
function IntroduceDetail({title}) {
    return ( 
        <>
            <Header navigate="/" />
            <m.main
               initial={{opacity: 0}}
               animate={{opacity: 1}}                
               exit={{opacity: 0}}
               transition={{duration: 0.5, ease: "easeInOut"}}
            className="introduceDetail">
                <div class="container">
                    <button class=" BackBtnBlack">
                        <Link to="/">
                            <img class="pointer-none" src="./images/icons/icon-blackArrowRight.svg" alt="arrow back" />
                        </Link>
                    </button>
                    <h1 id="isTranslated" class="text-center clr-main ff-main-semibold fs-lg-200">{title}</h1>
                    
                    <ul class="flexColCenter list" role="list" data-type="services">
                        <li class="item" data-type="services">
                            <div class="flexCenter itemBackGround" data-type="services left">
                                <div class="flexColCenter itemText" data-type="services left">
                                    <h1 id="isTranslated" class="fs-md-title clr-main ff-main-bold itemTitle" data-type="services">Khám phá "thiên đường nhiệt đới" ở Bali</h1>
                                    <p class="fs-sm-100 clr-main ff-main-regular">
                                        Illustrate all the servicesthat provide for customer. Example: Discover the magic of South Korea with our captivating tour.
                                        Immerse yourself in the vibrant energy of Seoul, explore ancient palaces, and savor delicious Korean cuisine. 
                                        From the bustling marketsto serene temples, each moment promises an enchanting experience.
                                    </p>
                                </div>
                            </div>
                        </li>               
                        <li class="item" data-type="services">
                            <div class="flexCenter itemBackGround" data-type="services right">
                                <div class="flexColCenter itemText" data-type="services right">
                                    <h1 id="isTranslated" class="fs-md-title clr-main ff-main-bold itemTitle" data-type="services">Bức tranh thiên nhiên đầy màu sắc theo từng mùa</h1>
                                    <p class="fs-sm-100 clr-main ff-main-regular">
                                        Illustrate all the servicesthat provide for customer. 
                                        Example: Discover the magic of South Korea with our captivating tour. Immerse yourself in the vibrant energy of Seoul, explore ancient palaces, and savor delicious Korean cuisine. 
                                        From the bustling marketsto serene temples, each moment promises an enchanting experience.
                                    </p>
                                </div>
                            </div>
                        </li>               
                        <li class="item" data-type="services">
                            <div class="flexCenter itemBackGround" data-type="services left">
                                <div class="flexColCenter itemText" data-type="services left">
                                    <h1 id="isTranslated" class="fs-md-title clr-main ff-main-bold itemTitle" data-type="services">Phong cảnh Hàn Quốc đẹp – TOP 7 Điểm đến</h1>
                                    <p class="fs-sm-100 clr-main ff-main-regular">
                                        Illustrate all the servicesthat provide for customer. Example: Discover the magic of South Korea with our captivating tour.
                                        Immerse yourself in the vibrant energy of Seoul, explore ancient palaces, and savor delicious Korean cuisine. 
                                        From the bustling marketsto serene temples, each moment promises an enchanting experience.
                                    </p>
                                </div>
                            </div>
                        </li>               
                        <li class="item" data-type="services">
                            <div class="flexCenter itemBackGround" data-type="services right">
                                <div class="flexColCenter itemText" data-type="services right">
                                    <h1 id="isTranslated" class="fs-md-title clr-main ff-main-bold itemTitle" data-type="services">Những nơi đẹp như tiên cảnh ở Trung Quốc</h1>
                                    <p class="fs-sm-100 clr-main ff-main-regular">
                                        Illustrate all the servicesthat provide for customer. Example: Discover the magic of South Korea with our captivating tour.
                                        Immerse yourself in the vibrant energy of Seoul, explore ancient palaces, and savor delicious Korean cuisine. 
                                        From the bustling marketsto serene temples, each moment promises an enchanting experience.
                                    </p>
                                </div>
                            </div>
                        </li>      
                        <li class="item" data-type="services">
                            <div class="flexCenter itemBackGround" data-type="services left">
                                <div class="flexColCenter itemText" data-type="services left">
                                    <h1 id="isTranslated" class="fs-md-title clr-main ff-main-bold itemTitle" data-type="services">Du Lịch Nepal Chinh Phục Dãy Núi Cao Nhất Thế Giới</h1>
                                    <p class="fs-sm-100 clr-main ff-main-regular">
                                        Illustrate all the servicesthat provide for customer. Example: Discover the magic of South Korea with our captivating tour.
                                        Immerse yourself in the vibrant energy of Seoul, explore ancient palaces, and savor delicious Korean cuisine. 
                                        From the bustling marketsto serene temples, each moment promises an enchanting experience.
                                    </p>
                                </div>
                            </div>
                        </li>                        
                    </ul>
                </div>
             </m.main> 
        </>
     );
}

export default IntroduceDetail;