import AboutUs from "../aboutus/AboutUs";
import OurCustomer from "../ourcustomer/OurCustomer";
import Services from "../services/Services";
import './introduction.css';

function Introduction() {
    return ( 
        <section class="introduceWrapper" id="details">
            <div class="container">
                <h1 class="fs-secondaryHeading clr-main ff-main-extraBold">INTRODUCE</h1>
                <div class="cardList flexColCenter" data-card="introduce">
                    <AboutUs />
                    <OurCustomer />
                    <Services />
                </div>
            </div>
        </section>
     );
}

export default Introduction;