import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import './aboutus.css';
import {motion as m} from "framer-motion";
function AboutUsDetail() {
    return ( 
        <>
            <Header navigate="/" />
            <m.main
               initial={{opacity: 0}}
               animate={{opacity: 1}}                
               exit={{opacity: 0}}
               transition={{duration: 0.5, ease: "easeInOut"}}
            className="aboutusDetail">
                <div class="container">
                    <button class="button aboutBackBtn">
                        <Link to="/">
                            <img class="pointer-none" src="./images/icons/icon-blackArrowRight.svg" alt="arrow back" />
                        </Link>
                    </button>
                    <h1 class="text-center clr-main ff-main-semibold fs-lg-200">ABOUT US</h1>
                    <div class="columns-auto aboutWrapper">
                        <div class="aboutLeft">
                            <img src="./images/introduce/aboutLeft.png" alt="always update trend" class="aboutImg" />
                            <div class="columns-auto aboutContent" data-type="left">
                                <h1 class="clr-black ff-main-regular aboutNumber">1</h1>
                                <p id="isTranslated" class="fs-nav ff-main-regular">Tại D-Tour, chúng tôi luôn cập nhật những xu hướng mới trong ngành dịch vụ - du lịch. 
                                    Đối với chúng tôi, tạo sự khác biệt và không ngừng đổi mới chính là 
                                    một trong những yếu tố quan trọng quyết định đến sự phát triển của công ty 
                                    không chỉ ở hiện tại mà còn ở tương lai.</p>
                            </div>
                        </div>

                        <div class="aboutRight">
                            <div class="columns-auto aboutContent" data-type="right">
                                <h1 class="clr-white ff-main-regular aboutNumber">2</h1>
                                <p id="isTranslated" class="fs-nav ff-main-regular clr-white">Tại D-Tour, chúng tôi luôn cập nhật những xu hướng mới trong ngành dịch vụ - du lịch. 
                                    Đối với chúng tôi, tạo sự khác biệt và không ngừng đổi mới chính là 
                                    một trong những yếu tố quan trọng quyết định đến sự phát triển của công ty 
                                    không chỉ ở hiện tại mà còn ở tương lai.</p>
                            </div>
                            <img src="./images/introduce/aboutRight.png" alt="always update trend" class="aboutImg" />
                        </div>
                    </div>
                </div>
            </m.main>
            <Footer />
        </>
     );
}

export default AboutUsDetail;