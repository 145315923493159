import { Route, Routes, useLocation } from "react-router";
import OutstandingDetail from "./pages/outstandingdetail/OutstandingDetail";
import PackageTourDetail from "./pages/packagetourdetail/PackageTourDetail";
import Home from "./pages/Home";
import AboutUsDetail from "./pages/aboutus/AboutUsDetail";
import IntroduceDetail from "./pages/introduction/IntroduceDetail";
import {AnimatePresence} from 'framer-motion'

function App() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/outstanding/:id" element={<OutstandingDetail />} /> 
        <Route path="/packagetourdetail/:id" element={<PackageTourDetail />} />
        <Route path="/aboutus" element={<AboutUsDetail />} />
        <Route path="/ourcustomer" element={<IntroduceDetail title="OUR CUSTOMER" />} />
        <Route path="/services" element={<IntroduceDetail title="SERVICES" />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
