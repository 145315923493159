import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useState } from "react";
import './packagedetails.css';
import {motion as m} from "framer-motion";

function PackageTourDetail() {
    const [currentIndexImg, setCurrentIndexImg] = useState(0);
    const [valueInputRange, setValueInputRange] = useState(20);

    const handleClickBtn = (btn) => {
        const imgList = document.querySelectorAll('.img-item');
        let lengthOfListImg = imgList.length;
        let index = 0;
        switch (btn) {
            case "prev":
                if(currentIndexImg != 0) {
                    index = currentIndexImg - 1;
                } else {
                    index = lengthOfListImg - 1;
                }
                document.querySelector('.img-item.active').classList.remove("active");
                imgList[index].classList.add("active");
                setCurrentIndexImg(index);
                break;
            case "next":       
                if(currentIndexImg < lengthOfListImg - 1) {
                    index = currentIndexImg + 1;
                } else {
                    index = 0;
                }
                document.querySelector('.img-item.active').classList.remove("active");
                imgList[index].classList.add("active");
                setCurrentIndexImg(index);
                break;
        
            default:
                throw new Error("invalid type!");
        }
    }
    return ( 
        <>
            <Header />
            <m.main
               initial={{opacity: 0}}
               animate={{opacity: 1}}                
               exit={{opacity: 0}}
               transition={{duration: 0.5, ease: "easeInOut"}}
            className="packagetourdetail"> 
                <section>          
                        <div className="packageButtonGroup container">
                            <button className="button">
                                <Link to="/">
                                    <img className="pointer-none" src="./images/icons/icon-blackArrowRight.svg" alt="icon arrow black right" />
                                </Link>
                            </button>
                        </div>
                        <div className="carousel">     
                            <button onClick={() => handleClickBtn("prev")} className="carouselBtn" id="prev">
                                <img src="./images/icons/icon-arrowLeft.svg" alt="arrow left" />
                            </button>           
                            <button onClick={() => handleClickBtn("next")} className="carouselBtn" id="next">
                                <img src="./images/icons/icon-arrowRight.svg" alt="arrow right" />
                            </button>  
                            <div className="img-wrapper">
                                <img className="img-item active" src="./images/packagetour/carousel_indonesia.png" alt="carousel" />                  
                                <img className="img-item" src="./images/outstanding/carousel_korea_2.png" alt="carousel" />                  
                                <img className="img-item" src="./images/outstanding/carousel_korea_3.png" alt="carousel" />                   
                            </div>         
                        </div>
                </section>

                
                <section className="packageMain">
                    <div className="container">
                        <h1 className="ff-main-semibold clr-main text-center">Indonesia Tour</h1>
                        <div className="filter flexCenter container" data-type="packagetour">
                            <ul className="flexCenter" role="list">
                                <li>
                                    <label htmlFor="date" className="clr-black  ff-main-semibold">Date</label>
                                    <select name="Date" id="date" className="ff-main-extralight clr-text bg-box">
                                        <option value="">Select Date</option>      
                                    </select>
                                </li>                     
                                <li>
                                    <label htmlFor="season" className="clr-black  ff-main-semibold">Season</label>
                                    <select name="Date" id="season" className="ff-main-extralight clr-text bg-box">
                                        <option value="">Select Season</option>
                                    </select>
                                </li>                     
                                <li>
                                    <label htmlFor="date" className="clr-black  ff-main-semibold">Country</label>
                                    <select name="Date" id="date" className="ff-main-extralight clr-text bg-box">
                                        <option value="">Select Country</option>
                                    </select>
                                </li>                     
                                <li className="duration flexCenter">
                                    <label htmlFor="duration" className="clr-black  ff-main-semibold">Duration</label>
                                    <div className="inputRangeWrapper flexColCenter">
                                        <input
                                        onInput={(e) => {setValueInputRange(e.target.value)}}
                                        type="range" min="2" max="20" value={valueInputRange} />
                                        <p className="text-center ff-main-light">1 Days - <span className="valueInputRange">{valueInputRange}</span> Days</p>
                                    </div>
                                </li>
                            </ul>                                 
                        </div>

                        <ul className="cardPackageList flexCenter" role="list">
                            <li>
                                <div className="cardPackageItem">
                                    <div className="cardPackageItemImgWrapper">
                                        <img src="../../images/package_tour/indonesia_1-1.png" alt="package tour details" className="cardPackageItemTop" />
                                        <img src="../../images/package_tour/indonesia_1-2.png" alt="package tour details" className="cardPackageItemTop" />
                                    </div>
                                    <div className="cardPackageItemContent">
                                        <p className="clr-main ff-main-regular cardPackageItemTitle">Name Tour - Name Tour - Name Tour</p>
                                        <p className="clr-text ff-main-medium">xx Days</p>
                                        <p className="clr-main ff-main-regular">Price <span className="ff-main-extraBold">99.000.000vnđ</span></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="cardPackageItem">
                                    <div className="cardPackageItemImgWrapper">
                                        <img src="../../images/package_tour/indonesia_2-1.png" alt="package tour details" className="cardPackageItemTop" />
                                        <img src="../../images/package_tour/indonesia_2-2.png" alt="package tour details" className="cardPackageItemTop" />
                                    </div>
                                    <div className="cardPackageItemContent">
                                        <p className="clr-main ff-main-regular cardPackageItemTitle">Name Tour - Name Tour - Name Tour</p>
                                        <p className="clr-text ff-main-medium">xx Days</p>
                                        <p className="clr-main ff-main-regular">Price <span className="ff-main-extraBold">99.000.000vnđ</span></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="cardPackageItem">
                                    <div className="cardPackageItemImgWrapper">
                                        <img src="../../images/package_tour/indonesia_3-1.png" alt="package tour details" className="cardPackageItemTop" />
                                        <img src="../../images/package_tour/indonesia_1-1.png" alt="package tour details" className="cardPackageItemTop" />
                                    </div>
                                    <div className="cardPackageItemContent">
                                        <p className="clr-main ff-main-regular cardPackageItemTitle">Name Tour - Name Tour - Name Tour</p>
                                        <p className="clr-text ff-main-medium">xx Days</p>
                                        <p className="clr-main ff-main-regular">Price <span className="ff-main-extraBold">99.000.000vnđ</span></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="cardPackageItem">
                                    <div className="cardPackageItemImgWrapper">
                                        <img src="../../images/package_tour/indonesia_4-1.png" alt="package tour details" className="cardPackageItemTop" />
                                        <img src="../../images/package_tour/indonesia_3-1.png" alt="package tour details" className="cardPackageItemTop" />
                                    </div>
                                    <div className="cardPackageItemContent">
                                        <p className="clr-main ff-main-regular cardPackageItemTitle">Name Tour - Name Tour - Name Tour</p>
                                        <p className="clr-text ff-main-medium">xx Days</p>
                                        <p className="clr-main ff-main-regular">Price <span className="ff-main-extraBold">99.000.000vnđ</span></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="cardPackageItem">
                                    <div className="cardPackageItemImgWrapper">
                                        <img src="../../images/package_tour/indonesia_5-1.png" alt="package tour details" className="cardPackageItemTop" />
                                        <img src="../../images/package_tour/indonesia_4-1.png" alt="package tour details" className="cardPackageItemTop" />
                                    </div>
                                    <div className="cardPackageItemContent">
                                        <p className="clr-main ff-main-regular cardPackageItemTitle">Name Tour - Name Tour - Name Tour</p>
                                        <p className="clr-text ff-main-medium">xx Days</p>
                                        <p className="clr-main ff-main-regular">Price <span className="ff-main-extraBold">99.000.000vnđ</span></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="cardPackageItem">
                                    <div className="cardPackageItemImgWrapper">
                                        <img src="../../images/package_tour/indonesia_3-1.png" alt="package tour details" className="cardPackageItemTop" />
                                        <img src="../../images/package_tour/indonesia_2-1.png" alt="package tour details" className="cardPackageItemTop" />
                                    </div>
                                    <div className="cardPackageItemContent">
                                        <p className="clr-main ff-main-regular cardPackageItemTitle">Name Tour - Name Tour - Name Tour</p>
                                        <p className="clr-text ff-main-medium">xx Days</p>
                                        <p className="clr-main ff-main-regular">Price <span className="ff-main-extraBold">99.000.000vnđ</span></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="cardPackageItem">
                                    <div className="cardPackageItemImgWrapper">
                                        <img src="../../images/package_tour/indonesia_1-2.png" alt="package tour details" className="cardPackageItemTop" />
                                        <img src="../../images/package_tour/indonesia_7-2.png" alt="package tour details" className="cardPackageItemTop" />
                                    </div>
                                    <div className="cardPackageItemContent">
                                        <p className="clr-main ff-main-regular cardPackageItemTitle">Name Tour - Name Tour - Name Tour</p>
                                        <p className="clr-text ff-main-medium">xx Days</p>
                                        <p className="clr-main ff-main-regular">Price <span className="ff-main-extraBold">99.000.000vnđ</span></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="cardPackageItem">
                                    <div className="cardPackageItemImgWrapper">
                                        <img src="../../images/package_tour/indonesia_8-1.png" alt="package tour details" className="cardPackageItemTop" />
                                        <img src="../../images/package_tour/indonesia_5-1.png" alt="package tour details" className="cardPackageItemTop" />
                                    </div>
                                    <div className="cardPackageItemContent">
                                        <p className="clr-main ff-main-regular cardPackageItemTitle">Name Tour - Name Tour - Name Tour</p>
                                        <p className="clr-text ff-main-medium">xx Days</p>
                                        <p className="clr-main ff-main-regular">Price <span className="ff-main-extraBold">99.000.000vnđ</span></p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </m.main>

            <Footer />
        </>
     );
}

export default PackageTourDetail;