import { Link } from "react-router-dom";

function Services() {
    return ( 
        <div class="cardItem" data-card="introduce">
            <Link to="/services"><p class="clr-main ff-main-bold fs-lg-100">Services</p></Link>
        </div>
     );
}

export default Services;